import React, { ReactElement } from 'react';

const EULA = (): ReactElement => {
  return (
    <div>
      <b>LINK</b>
      <br />
      <br />
      <b>Vessel Management Eco-System</b>
      <br />
      <br />
      <b>END USER LICENSE AGREEMENT (EULA)</b>
      <br />
      <br />
      <b>PREAMBLE</b>
      <br />
      <br />
      <b>General Use of LINK Products and Services</b>
      <br />
      <br />
      Products contained with the LINK eco-system (defined below) are intended to supplement
      existing systems installed on your vessel. They should not be used to replace navigation,
      security, safety critical, or regulatory systems, or other systems that enable the safe use
      and functioning of your vessel. LINK solutions should not be interacted with in situations
      where it may be distracting or unsafe to do so. LINK accepts no liable where systems are used
      contrary to their recommendations.
      <br />
      <br />
      <b>Terms of Use</b>
      <br />
      <br />
      PLEASE READ THIS LINK END USER LICENSE AGREEMENT (&quot;EULA&quot;) CAREFULLY. The EULA is a
      modular contract that governs and defines the terms of the contractual relationship between
      Smart Yachts International Limited trading as LINK (&quot;LINK&quot;) and its customers
      (&quot;Customer&quot;).
      <br />
      <br />
      The EULA consists of the following components:
      <br />
      <br />
      <b>A. Base Terms</b>
      <br />
      <br />
      The Base Terms contain the terms and conditions generally applicable for your contractual
      relationship with LINK. This part of the EULA will apply to you in all cases.
      <br />
      <br />
      <b>B. Software Specific Terms</b>
      <br />
      <br />
      The Software Specific Terms contain the terms and conditions that additionally apply to the
      use of:
      <br />
      <br />
      1. Certain software provided by LINK, whether installed on devices of the Customer or accessed
      via web browser, also including any applications (e.g. apps for mobile devices), add-on
      components or subscriptions, customized settings and features, and all updates and Release
      Versions as herein below defined thereof (collectively &quot;Software&quot;), and 2. Servers
      for the establishment of encrypted connections (handshake) and for the forwarding of data
      packets (routing) in connection with the use of the Software (&quot;Server Services&quot;), as
      well as 3. Any further cloud-based services provided by LINK. The Software, Server Services
      and other cloud-based services provided by LINK are hereinafter collectively referred to as
      &quot;Services&quot;.
      <br />
      <br />
      <b>C. Hardware Specific Terms</b>
      <br />
      <br />
      The Hardware Specific Terms contain the terms and Conditions that additionally apply to your
      purchase and/or lease of physical goods, including LINKbridge, LINKMini, LINKRouter Devices or
      similar items (&quot;Hardware&quot;).
      <br />
      <br />
      <b>D. Product Specific Terms</b>
      <br />
      <br />
      The Product Specific Terms include terms and conditions that additionally apply to your use of
      a specific type of LINK product (each, a &quot;Product&quot;), if and to the extent the terms
      and conditions for a specific LINK Product are provided herein. The Product Specific Terms
      include a link to the description of features, limits of use and system requirements, as of
      the date of the Contract.
      <br />
      <br />
      Where applicable, and for informational purposes only, the Product Specific Terms also link to
      the corresponding:
      <br />
      <br />
      - Data Processing Agreement (DPA): This explains and regulates how LINK processes data on your
      behalf; and - Product Privacy Policy: This policy describes how LINK, in its function as a
      data controller, collects, uses, stores and processes your Personal Data in connection with
      your use of the service. It also describes your rights of information, i.e., of access to and
      correction of your personal data.
      <br />
      <br />
      <b>E. Jurisdiction Specific Terms</b>
      <br />
      <br />
      The terms of jurisdiction for this agreement.
      <br />
      <br />
      <b>A. Base Terms</b>
      <br />
      <br />
      <b>
        The Base Terms contain the terms and conditions generally applicable for your contractual
        relationship with LINK. This part of the EULA will apply to you in any case.
      </b>
      <br />
      <br />
      <b>A.1. Formation of Contract</b>
      <br />
      <br />
      A contract between LINK and Customer pursuant to this EULA shall be formed, if:
      <br />
      <br />
      1. Customer submits an order on the LINK web shop (www.smartyachts.link) or LINKCloud
      (cloud.smartyachts.link or manufacturer branded access points in the format
      *.smartyachts.link) by clicking on the &quot;Place Order&quot; / &quot;Subscribe&quot; /
      &quot;Purchase&quot; / &quot;Upgrade&quot; or similarly named button after having accepted
      this EULA, and LINK accepts the said order, for example by sending an order confirmation or
      license activation to the Customer; or
      <br />
      <br />
      1. If LINK issues a binding quotation to Customer referencing this EULA, and Customer accepts
      said quotation within the prescribed term set out in the quotation or, if no term is
      specified, within 21 days after issuance date; or 2. If Customer and LINK enter into a
      contract referencing this EULA in any other form. A contract shall also be formed if the
      Customer registers an account on the LINK Cloud, or as Customer downloads the Software (as
      defined below) free-of-charge, whether as Free Cloud Access, for Trial Use or test period (as
      defined in the Software Specific Terms), configures the type of use and installs the Software
      after having accepted this EULA; or
      <br />
      <br />
      1. If Customer acquires LINK Hardware from an authorized dealer of LINK
      (&quot;Reseller&quot;), this EULA, shall apply in relation between Customer and LINK,
      effective as from the time of acquisition of the Hardware. Any deviating terms, warranties and
      commitments agreed upon between the Reseller and Customer shall not be binding upon LINK.
      <br />
      <br />
      The contractual details as agreed in the individual transaction documents as per process
      described above and the EULA together shall form the &quot;Contract&quot;.
      <br />
      <br />
      <b>A.2. Fees and Prices</b>
      <br />
      <br />
      Customer shall pay LINK the price specified in the Contract.
      <br />
      <br />
      <b>2.1 Due Date</b>
      <br />
      <br />
      Unless otherwise specified in the Contract, all fees shall be due upon invoicing.
      <br />
      <br />
      <b>A.2.2 Invoicing</b>
      <br />
      <br />
      Unless otherwise specified in the Contract, LINK shall invoice the respective fee at the date
      of the Contract. If applicable, LINK shall in addition invoice the respective fee subsequently
      at the beginning of each Renewal Term. The invoicing shall be made:
      <br />
      <br />
      1. Online via an email to the email address provided by the Customer; or 2. If such an account
      has been created – through an upload into the Customer&#39;s LINK account and/or the
      notification of the Customer via email. The Customer shall only be entitled to the delivery of
      an invoice by mail if the Customer requests the invoice from LINK and pays the respective fee
      specified in LINK&#39;s applicable price list.
      <br />
      <br />
      <b>A.2.3 Payment methods</b>
      <br />
      <br />
      The invoiced amounts may be paid by credit card. Further payment methods (e.g., SEPA direct
      debit or check) may be offered during the ordering process.
      <br />
      <br />
      <b>A.2.4 Prices, fees, and tax</b>
      <br />
      <br />
      The fees and charges specified in the Contract do not include any sales, use, consumptions,
      value-added, or any other tax (including applicable withholding tax, which shall be added to
      the invoiced amount if applicable). Customer is responsible for the payment of any and all
      such taxes. Bank and credit card charges shall be borne by the Customer. All fees and charges
      shall be payable immediately and, in the currency specified on the Contract, unless another
      payment period was agreed.
      <br />
      <br />
      <b>A.2.5 Price changes</b>
      <br />
      <br />
      For a Contract that renews at the end of the respective Initial Term or Renewal Term (as
      defined below), LINK may notify the Customer of changes to the fee at least twenty-eight (28)
      days prior to the expiry of the term of the Contract (section B. 5.1). The Customer may object
      to the increase within fourteen (14) days of the notification, in which case the Contract
      terminates at the end of the respective Initial Term or Renewal Term. If the Customer does not
      object, the notified user fee shall apply with effect from the Renewal Term following the
      notification. LINK shall inform the Customer in its notification about this effect of
      non-objection.
      <br />
      <br />
      <b>A.2.6 Late payment</b>
      <br />
      <br />
      Any overdue payment shall accrue an interest at the rate provided by applicable law. In
      addition, the following provisions shall apply:
      <br />
      <br />
      - Reminder fee: In the case of a second payment reminder, LINK shall be entitled to charge a
      suitable reminder fee. - Termination in case of default: LINK may terminate the Contract if
      the Customer defaults on the payment of the fee and fails to cure the breach within fifteen
      (15) days of receiving notice from LINK. Termination is in addition to (and not in lieu of)
      any other rights and remedies available to LINK hereunder or at law. - Suspension in case of
      default: If the Customer defaults on the payment of the user fee, LINK shall be entitled to
      suspend the Server Services temporarily (&quot;Suspension&quot;). However, LINK shall warn the
      Customer of the Suspension reasonably in advance, e.g., via email or notifications in the
      Software. The Suspension shall not take place or respectively be rescinded without undue delay
      once the Customer has made his payment in full. During the Suspension, no connections can be
      established from and to the installations of the Customer&#39;s Software. The Customer&#39;s
      obligation to pay the user fee shall continue to be in effect during the Suspension period.
      <br />
      <br />
      <b>A.2.7 Invoicing entities</b>
      <br />
      <br />
      Customer acknowledges and agrees that the payment shall be collected and processed by LINK.
      The processing entity may vary depending on region.
      <br />
      <br />
      <b>A.3. Confidentiality</b>
      <br />
      <br />
      The Products, including the Software, the Services and all manuals, as well as both
      parties&#39; data, documentation, and other materials provided by one party (&quot;Disclosing
      Party&quot;) to the other party (&quot;Receiving Party&quot;), contain, as applicable,
      essential components (e.g. algorithm and logic), constituting confidential information and
      trade secrets and shall be deemed the Disclosing Party&#39;s confidential information
      (&quot;Confidential Information&quot;). The Receiving Party will only use Confidential
      Information in accordance with the Contract and disclose Confidential Information to any third
      party only as required to fulfil or comply with its obligations under this Contract and only
      if the third party is bound by confidentiality obligations which are at least as protective to
      the Receiving Party as those set forth in this Confidentiality section.
      <br />
      <br />
      All software issued by LINK remains the property of LINK. This software is the intellectual
      property of LINK, and is protected by law, including English and International copyright laws.
      Purchasing LINK hardware grants Customer a non-exclusive, non-transferable, limited and
      revocable license to use the included, pre-installed software on the device. Customer may not
      decrypt, reverse engineer, modify, translate, disassemble or decompile any of LINK software or
      hardware in whole or in part. Any modifications will immediately terminate all licenses held
      and full compensation for any and all infringements will be sought.
      <br />
      <br />
      <b>A.4. Data protection</b>
      <br />
      <br />
      LINK complies with applicable data protection law. LINK collects, processes and uses personal
      data of Customer in its function as a data controller as set out and in accordance with the
      relevant Privacy Policy. Additionally, LINK may act as a processor for Customer&#39;s personal
      data as set out and in accordance with the terms and conditions of the Data Processing
      Agreement (DPA) provided separately under this link. The DPA does not apply if Customer is a
      natural person using the Software or the Services in the course of a purely personal or family
      activity (cf. Art. 2(2)(c) EU General Data Protection Regulation, &quot;GDPR&quot;).
      <br />
      <br />
      <b>A.5. Non-personal data</b>
      <br />
      <br />
      LINK may process non-personal or anonymous data to improve functionality and the
      Customers&#39; experience with the Products, including the Software and the Services. Customer
      agrees that LINK owns all rights in and is free to use any such non-personal or anonymous data
      in any way it deems fit for development, diagnostic, corrective as well as marketing or any
      other purposes.
      <br />
      <br />
      <b>A.6. Changes to the EULA</b>
      <br />
      <br />
      LINK shall be entitled to amend this EULA upon no less than twenty-eight (28) days prior
      notice to Customer. Unless Customer notifies LINK in writing of its objection to such
      amendment within fifteen (15) days of such notice, then the amendment shall be deemed accepted
      by Customer. If, on the other hand, Customer notifies LINK in writing of its objection to such
      amendment within fifteen (15) days of its receipt of notice the Contract shall be continued
      under the existing terms without giving effect to such amendment.
      <br />
      <br />
      <b>A.7. No deviating provisions</b>
      <br />
      <br />
      The Contract contains the entire agreement between the parties with respect to the subject
      matter hereof, and supersedes all proposals, understandings, representations, warranties,
      covenants, and any other communications (whether written or oral) between the parties relating
      thereto and is binding upon the parties and their permitted successors and assigns. Any
      inconsistent or conflicting terms and conditions contained in any purchase order or similar
      instrument of Customer shall be of no force or effect, unless LINK has explicitly approved
      such terms and conditions in writing. This requirement of explicit written form approval
      applies in particular to Customer&#39;s terms and conditions, regardless of whether LINK
      provides Software or Services to the Customer in knowledge of the Customer&#39;s general terms
      and conditions without explicitly objecting to them.
      <br />
      <br />
      <b>A.8. Warranties; no guarantees</b>
      <br />
      <br />
      Warranties in this EULA refer to the description of remedies available to Customer in case of
      non-performance or defective performance and shall not be construed as a guarantee that stands
      for strict liability without fault. A guarantee of LINK for quality or committed features in
      the meaning of preceding clause shall only be taken as such if made by LINK in writing
      (including a signature) and labelled expressively as &quot;guarantee&quot;.
      <br />
      <br />
      <b>A.9. Severability; waiver</b>
      <br />
      <br />
      If any provision of the Contract is found partly or wholly invalid or unenforceable, such
      provision shall be enforced to the maximum extent permissible, and remaining provisions of the
      Contract shall remain in full force and effect. A waiver of any breach or default under the
      Contract shall not constitute a waiver of any other subsequent breach or default.
      <br />
      <br />
      <b>A.10. Communication via email</b>
      <br />
      <br />
      Unless otherwise specified in the Contract, any notifications and declarations in connection
      with the Contract may also be made by email. To this end, LINK may use the email address the
      Customer provided upon registration or in the LINK account. The Customer shall be responsible
      for checking its emails regularly and, if necessary, updating its email address.
      <br />
      <br />
      <b>A.11. Documentation</b>
      <br />
      <br />
      User manuals are retrievable online in English. Any further languages may be offered by LINK
      at its sole discretion. As between the parties, LINK retains all right, title and interest in
      and to such documentation and in all copies, modifications and derivative works thereof
      including, without limitation, all rights to patent, copyright, trade secret, know-how,
      trademark and other proprietary or intellectual property rights.
      <br />
      <br />
      <b>B. Software Specific Terms</b>
      <br />
      <br />
      <b>
        The Software Specific Terms contain the terms and conditions that additionally apply to the
        use of:
      </b>
      <br />
      <br />
      1. Certain software provided by LINK, whether installed on devices of the Customer or accessed
      via web browser, also including any applications (e. g. apps for mobile terminals), addon
      components or subscriptions, customized settings and features, and all updates and Release
      Versions as hereinbelow defined thereof (collectively &quot;Software&quot;), and
      <b>
        {' '}
        2. Servers for the establishment of encrypted connections (handshake) and for the forwarding
        of data packets (routing) in connection with the use of the Software (&quot;Server
        Services&quot;), as well as 3. Any further cloud-based services provided by LINK. The
        Software, Server Services and other cloud-based services provided by LINK are hereinafter
        collectively referred to as &quot;Services&quot;.
      </b>
      <br />
      <br />
      <b>B.1. Licenses</b>
      <br />
      <br />
      <b>B.1.1 Subscription and Free License</b>
      <br />
      <br />
      For the use of Software and Server Services, Customer shall acquire the right of use
      (&quot;License&quot;) respectively as:
      <br />
      <br />- i. a temporary, i.e., term- or subscription-based, license against recurring payments
      (&quot;Subscription&quot;); or - ii. a limited license free-of-charge (&quot;Free&quot;),
      granted by LINK for selected products (including basic LINKCloud access obtained through
      registering an account on LINK), under the conditions herein below. -{' '}
      <b>B.1.1.1 Subscription</b>
      <br />
      <br />
      Licenses for Subscription are provided in two formats:
      <br />
      <br />
      1. LINK hardware subscriptions – these provide the ability for Customer to integrate LINK
      hardware to the LINKCloud. There are various subscriptions options available which determine
      the available features Customer will receive. Where a subscription is provided free of charge
      or discounted (i.e. in the first 12 months of purchase) the contract still applies as though
      there had been an exchange of monies. 2. LINK Data subscriptions – these provide Customer the
      ability to use and manage mobile data through LINK Cloud, using LINK provided SIM cards.
      <br />
      <br />
      <b>B.1.1.2 Free Cloud Access</b>
      <br />
      <br />
      LINK provides the ability for Customer to register accounts on the LINKCloud free of charge.
      This provides limited functionality compared to a subscription.
      <br />
      <br />
      <b>B.1.1.3 Test period</b>
      <br />
      <br />
      If the Contract provides for a test period, Customer may terminate the Contract within seven
      (7) calendar days of the conclusion of the Contract. In such a case, Customer shall be
      provided with a refund of any prepaid, unused fees (if any) paid to LINK for the applicable
      Software that is subject to the test period.
      <br />
      <br />
      <b>B.1.1.4 Trial Use</b>
      <br />
      <br />
      Prior to entering into a Contract for Subscription, LINK may offer to Customer the opportunity
      for a trial use for the Software and the Server Services during a defined trial use period
      (&quot;Trial Use&quot;). For any Trial Use, this EULA shall also apply.
      <br />
      <br />
      <b>B.1.2 Scope of License</b>
      <br />
      <br />
      The License is granted to Customer herein as non-exclusive, worldwide (subject to applicable
      export control regulations; unless Customer is expressly granted a limited right to use the
      Service only in a specific territory in the Contract), non-transferable and not
      sub-licensable, and limited to the right to install, run and use the Software on
      Customer&#39;s own devices, or any devices in the immediate possession of Customer, within the
      limits of the scope of use specified in the Contract. Where software is pre-installed on LINK
      Hardware, Customer may not copy, run, or install this software on other hardware.
      <br />
      <br />
      The rights of use granted under the Contract for Subscription shall be limited in time to the
      specified term of Subscription and end with the expiry or termination thereof.
      <br />
      <br />
      <b>B.1.3 Authorized Users</b>
      <br />
      <br />
      Customer may only provide use of its acquired License to the Authorized Users.
      &quot;Authorized User&quot; means:
      <br />
      <br />
      1. If Customer is an individual, solely Customer or their invited users (subject to the
      features of their subscription); 2. If Customer is a legal entity, any current employees,
      agents, representatives or temporary workers authorized by Customer to use the Software or
      Services solely for the support of Customer&#39;s internal business, provided that such access
      and use shall be limited to their provision of services to Customer in scope of their
      employment or assignment; 3. In any other cases subject to written consent by LINK.
      <br />
      <br />
      Customer is responsible for the acts and omissions of its Authorized Users, as well as any
      other person that accesses and uses the Software and Service by using the access credentials
      provided by Customer, as its own acts and omissions. All obligations of Customer under this
      EULA and the respective Contract shall apply fully to any such Authorized Users or other
      persons as if they were Customers hereunder.
      <br />
      <br />
      <b>B.1.4 Overuse by Quantity</b>
      <br />
      <br />
      The usable licensed units included in the scope of license acquired by Customer, in particular
      regarding the amounts of licensed users or agents, managed devices, experts and annually
      allowed reassignments, etc., shall be specified in the Contract; where the Contract does not
      contain a specification, the usable licensed units available under the respective license type
      as stated in the Product Description shall apply. If Customer exceeds the quantity of licensed
      units during the term of Contract, additional license fees will be charged for the excess at
      the then current list price of LINK or, at the election of LINK, pro rata relating to the
      price provided in the Contract.
      <br />
      <br />
      <b>B.1.5 Prohibited use</b>
      <br />
      <br />
      Any use of Software and/or Server Services that is not expressly granted is prohibited. In
      particular, Customer shall not, or allow a third party to:
      <br />
      <br />
      1. attempt to circumvent any technical devices of the Software that are directed at, or have
      the effect of, enforcing the terms of the EULA; 2. Modify, create derivative works, translate,
      decompile or create or attempt to create, by reverse engineering or otherwise, the source code
      or the object code of the Software; 3. Use the Software or Hardware under any circumstance
      whatsoever directly or indirectly in a way that may contradict the laws of the country in
      which the software is operating; 4. Remove, modify or obscure any copyright, trade secret,
      confidentiality, trademark, service mark or other proprietary rights, serial number, notice,
      legend or similar on any copy of the Software, or related data, manuals, documentation or
      other materials; 5. Market, sell, lend, rent, lease, or otherwise distribute, the Software or
      Hardware, or provide access to Services to third parties outside of the designated use; or 6.
      Except as otherwise expressly provided herein, assign, sublicense or otherwise transfer any
      rights in or to the Software. This shall not affect Customer&#39;s mandatory statutory rights.
      <br />
      <br />
      <b>B.1.6 Reservation of rights</b>
      <br />
      <br />
      As between the parties, LINK retains all right, title and interest in and to the Software
      (including any customization and Release Versions) and in all copies, modifications and
      derivative works of the Software including, without limitation, all rights to patent,
      copyright, trade secret, know-how, trademark and other proprietary or intellectual property
      rights, even if provided or otherwise contributed to by the Customer.
      <br />
      <br />
      <b>B.1.7 Source code</b>
      <br />
      <br />
      The rights of use granted shall not include any rights to the source code of the Software.
      <br />
      <br />
      <b>B.1.8 Usage analyses, right to audit and self-declaration</b>
      <br />
      <br />
      LINK may analyze Customer&#39;s use of the Software and/or Services for security reasons as
      well as for product improvement, license auditing and/or marketing purposes. To that end, LINK
      may use, at its sole discretion, and implement technical measures regarding the functionality
      of the Software and the Server Services to assess whether Customer&#39;s usage pattern is in
      line with the indicated usage volume and thus with the chosen license type, and to detect
      whether the contractually agreed scope of use is being exceeded by Customer. LINK may require
      Customer, at any time, to provide a self-declaration regarding its actual scope of use and/or
      usage pattern.
      <br />
      <br />
      <b>B.2. Services</b>
      <br />
      <br />
      <b>B.2.1 Product Specification</b>
      <br />
      <br />
      The functions and features of the Software and Server Services are set forth, respectively, in
      these Software Specific Terms, in the Product Specific Terms and/or, as the case may be, in an
      annex to the respective Contract (in each case, a &quot;Product Specification&quot;).
      <br />
      <br />
      <b>B.2.2 Product Specification for Free LINK Access</b>
      <br />
      <br />
      Free access to the LINK Cloud is provided by LINK to Customer. Access may be revoked at any
      time. Free LINK Access is intended only as a gateway to enable the customer to become
      associated to a subscription product (for example, for them to have access to a vessel with a
      licensed subscription provided through the installation of LINK hardware). All other use of
      the Free LINK Access is prohibited.
      <br />
      <br />
      <b>B.2.3 System Requirement</b>
      <br />
      <br />
      Customer is responsible for providing the system environment required for the use of
      respective Software in accordance with the system requirements set forth in the Product
      Specific Terms (&quot;System Requirement&quot;). In the case of LINKCloud and LINK App this
      primarily refers to hardware capable of running both the web and smart phone applications.
      <br />
      <br />
      <b>B.2.4 Provision, installation and configuration of Software</b>
      <br />
      <br />
      Depending on the software types, Software may be provided for electronic download or made
      accessible via web browser. Customer shall be solely responsible for the download,
      installation and configuration of the Software. Customized adjustment, development,
      integration with Customer´s system or external software, training of Customer´s staff, as well
      as any additional consulting or support services other than warranty claims provided
      hereunder, are not included in the scope of Services and shall be subject to express written
      agreement.
      <br />
      <br />
      <b>B.2.5 Server Services</b>
      <br />
      <br />
      For the establishment of encrypted remote communication connections between different users of
      the Software, and between the servers and LINK Hardware, the Software must communicate with
      servers of LINK (so-called &quot;handshake&quot;). In addition, it is necessary in order for
      LINK hardware to enable the fulfilment of the solutions design (e. g. sending data to and from
      vessel systems) that encrypted data packets be forwarded by servers of LINK (so-called
      &quot;routing&quot;). Such handshake and routing are made available in connection with
      LINK&#39;s Server Services.
      <br />
      <br />
      LINK provides Customer with the Server Services subject to the conditions set out in this
      EULA. Customer acknowledges that the Server Services may be unavailable or include latency
      from time to time due to causes beyond the reasonable control of LINK. Additionally, Customer
      acknowledges that the end-to-end connection between different users of the Software is
      dependent on Customer&#39;s internet connection to the data center as well as Customer&#39;s
      use of hardware and software (e. g. PC, operating system) in compliance with System
      Requirements for the Software, all of which shall not be included in the services provided by
      LINK and shall be Customer&#39;s responsibility at its own costs.
      <br />
      <br />
      In case of Free Cloud Access, Customer has no claim to demand the provision of the Server
      Services and LINK may in its sole discretion discontinue or modify the Server Services at any
      time.
      <br />
      <br />
      <b>B.2.6 LINKCloud Account</b>
      <br />
      <br />
      LINK requires Customer (and its Authorized Users) to sign up for a LINK account and being
      logged in to the LINK account to be able to use the Services. LINK may further set certain
      account verification requirements that Customer will be required to meet to use the Services.
      <br />
      <br />
      <b>B.2.7 Programming interfaces</b>
      <br />
      <br />
      At LINK&#39;s discretion, it may provide use of programming interfaces or other software
      interfaces (&quot;API&quot;) which may enable applications of third parties or of Customer
      (collectively &quot;Third-party Software&quot;) to communicate with the Software or the
      servers provided by LINK as part of the Server Services. LINK, in its sole discretion, may
      change or switch off APIs at any time without any obligation or liability to Customer. The
      respective provider shall be responsible for the Third-party Software. The provisions of the
      Contract shall not apply to any such Third-party Software and LINK is under no obligation to
      test, validate or otherwise review Third-party Software, and shall have no liability for any
      Third-party Software or in connection with the use thereof.
      <br />
      <br />
      <b>B.2.8 Changes to the Services</b>
      <br />
      <br />
      Some features and functions of the Software or Server Services provided by LINK may include or
      depend on certain third-party components which may be subject to changes by such third
      parties. LINK is entitled to modify or limit such features and functions, provided this does
      not materially interfere with the substantial functions of Services.
      <br />
      <br />
      LINK reserves the right to change the Software in the context of updates and/or Release
      Versions as well as the Server Services (including the System Requirements) for good cause.
      Such good cause exists especially if the change is required due to:
      <br />
      <br />
      1. a necessary adaptation required by applicable law, regulation, court order, or order of
      authority; 2. changes to applicable technical framework conditions (e.g., new encryption
      standards); 3. the protection of system security; or 4. any other reason which leads LINK to
      determine this to be necessary.
      <br />
      <br />
      <b>B.2.9 Release Versions</b>
      <br />
      <br />
      LINK may, at its sole discretion, but shall not be obligated to, provide releases of the
      Software for download (&quot;Release Versions&quot;). Additional features to the Software
      which are separately marketed and/or priced by LINK (&quot;Additional Features&quot;) shall
      not qualify as Release Versions. All rights of use set forth in the Contract applicable to the
      Software shall also apply to Release Versions.
      <br />
      <br />
      Customer is obliged to update the Software with any Release Version at its own cost as soon as
      reasonably practicable. Customer&#39;s systems shall comply with the System Requirements to
      accommodate new Release Versions. Any malfunctioning of the Software or failure in the
      Services that is attributable to non-compliance with this section shall be Customer&#39;s sole
      responsibility.
      <br />
      <br />
      The obligation of Customer holding a previously acquired perpetual license to update the
      Software shall be limited to the minor Release Versions (e.g., version XX.1, XX.2 &quot;Minor
      Release Version&quot;) relating to the main version (e.g., version XX, YY) for which the
      Perpetual License was acquired. Minor Release Versions may contain the correction of errors,
      security patches as well as minor improvements of functions (e.g., optimizations in the
      program execution speed) and will be marked by LINK – in its sole discretion – by a change in
      the number behind the main version number.
      <br />
      <br />
      <b>B.2.11 Software Lifecycle Policy</b>
      <br />
      <br />
      Use of the Software shall be subject to LINK&#39;s Software Lifecycle Policy. At present,
      software will continue to be supported for 6 months after being made no longer available.
      <br />
      <br />
      <b>B.3. Customer Obligations</b>
      <br />
      <br />
      <b>B.3.1 Lawful Use</b>
      <br />
      <br />
      Customer shall use the Software and the Server Services only in accordance with the provisions
      of the Contract and in accordance with laws and regulations applicable to such use, in
      particular all applicable data protection and export control provisions and shall not infringe
      any third-party rights in connection with such use.
      <br />
      <br />
      <b>B.3.2 Export controls and economic sanctions</b>
      <br />
      <br />
      Customer acknowledges that the Software and related technical data as well as the Services
      (collectively &quot;Controlled Technology&quot;) are subject to the import and export control
      and economic sanctions laws of England and Wales, the European Union and the United States,
      specifically the U.S. Export Administration Regulations (EAR) and the laws of any country
      where Controlled Technology is imported or re-exported. Customer agrees to comply with all
      relevant laws and will not export, re-export, or transfer any Controlled Technology in
      contravention of English, EU or U.S. law nor to any restricted country, entity, or person for
      which an export license or other governmental approval is required. Customer further agrees
      that it will not export, transfer, or sell any Controlled Technology for use in connection
      with chemical, biological, or nuclear weapons, or missiles, drones or space launch vehicles
      capable of delivering such weapons.
      <br />
      <br />
      Customer represents that it is not:
      <br />
      <br />
      1. a Restricted Party (as defined below); 2. currently engaging in any transaction, activity
      or conduct that could result in a violation of applicable Sanctions (as defined below) and
      warrants that it will not make available the Controlled Technology directly or indirectly, to,
      or for the benefit of, any Restricted Party.
      <br />
      <br />
      This section shall only apply to Customer to the extent that the provisions herein would not
      result in:
      <br />
      <br />
      1. any violation of, conflict with or liability under EU Regulation (EC) 2271/1996 or 2. a
      violation or conflict with a similar antiboycott statute.
      <br />
      <br />
      &quot;Restricted Party&quot; means any person (i) designated on any Sanctions List, (ii) that
      is, or is part of, a governmental authority of a Sanctioned Territory, (iii) owned or
      controlled by, or acting on behalf of, any of the foregoing, (iv) located, organized, or
      resident in, or operating from, a Sanctioned Territory, or (v) otherwise targeted under any
      Sanctions.
      <br />
      <br />
      &quot;Sanctioned Territory&quot; means any country or other territory subject to a general
      export, import, financial or investment embargo under Sanctions.
      <br />
      <br />
      &quot;Sanctions&quot; means economic or financial sanctions or trade embargoes or other
      comprehensive prohibitions against transaction activity pursuant to anti-terrorism laws or
      export control laws imposed, administered or enforced from time to time by the US, EU, UN, UK,
      or any country where Controlled Technology is imported or re-exported.
      <br />
      <br />
      <b>B.4. Limitation of Warranty</b>
      <br />
      <br />
      <b>B.4.1 Limited warranty for Subscription</b>
      <br />
      <br />
      LINK shall, subject to this section B (Server Services), maintain Software and Server Services
      substantially in accordance with the applicable Product Description using commercially
      reasonable care and skills during the term of Subscription.
      <br />
      <br />
      <b>B.4.2 Maintenance</b>
      <br />
      <br />
      The maintenance by LINK set out herein does not include (i) any adaptation of the Software to
      new operating systems or new operating system versions, (ii) adaptation of the Software to the
      scope of functions of competing products, (iii) establishment of compatibility with new data
      formats or Release Versions or (iv) provision of any functionality which is no longer
      supported by LINK.
      <br />
      <br />
      <b>B.4.3 Error elimination</b>
      <br />
      <br />
      Customer may report any Errors in the Services preferably via the web portal provided by LINK.
      &quot;Error&quot; means any defect or malfunction that causes:
      <br />
      <br />
      1. the Software or Server Services to fail to perform the substantial functionality and/or
      expressly committed features in the respective Subscription, or 2. the use of the Services by
      Customer to be unavailable or impaired in material aspects. Minor or immaterial deviations
      from the agreed or assumed characteristics or just slight impairment of use shall not be
      deemed as Errors.
      <br />
      <br />
      LINK shall use commercially reasonable efforts to eliminate Errors within a reasonable period
      of time following Customer&#39;s notification of such Errors, for which Customer shall provide
      comprehensive details of the circumstances relating to the Errors and supporting documentation
      (e.g., screenshots, protocol data) in its notification, as far as this is possible and can be
      reasonably expected. LINK may, at its sole option, eliminate Errors by delivering patches or
      updates, through Release Versions or otherwise. If the elimination of an Error is not
      available using financially reasonable efforts within a predictable time, LINK shall be
      entitled to provide temporary workarounds for such Error, provided that the functionalities
      and availability of the Services are not materially affected.
      <br />
      <br />
      <b>B.4.4 Infringement of third party right</b>
      <br />
      <br />
      If Software infringes a third party&#39;s patent or copyright, LINK will, at its sole
      discretion:
      <br />
      <br />
      1. obtain for Customer the right to continue using the Software; or 2. replace or modify the
      Software so that it no longer infringes the relevant intellectual property right.
      <br />
      <br />
      If neither of the remedies in (i) or (ii) are reasonably available, either Party shall have
      the right to terminate the Contract with immediate effect.
      <br />
      <br />
      <b>B.4.5 Claims for damages</b>
      <br />
      <br />
      Any potential claims for damages based on a breach of warranty or defective performance shall
      be subject to the limitation of liability stipulated in the Jurisdiction Specific Terms.
      <br />
      <br />
      <b>B.4.6 No warranty for Free License</b>
      <br />
      <br />
      Software and Server Services under Free License are provided as-is. LINK assumes therefore no
      maintenance obligations and no warranty in relation to Customer holding a Free License, except
      in the case of fraudulent concealment of defect.
      <br />
      <br />
      <b>B.4.7 Limitation period</b>
      <br />
      <br />
      Customer´s remedies and claims for defects shall expire twelve (12) months after the
      circumstances giving rise to the claim have been discovered by Customer or, in the event of
      ignorance, twelve (12) months after Customer should reasonably have been aware of them. This
      shall not apply to the case of fraudulent concealment of defect, or a guarantee period
      expressly granted by LINK for a longer period.
      <br />
      <br />
      <b>B.5. Term and Termination</b>
      <br />
      <br />
      <b>B.5.1 Term, auto-renewal and ordinary termination of Subscription</b>
      <br />
      <br />
      Unless otherwise specified in the Contract, the initial term of the Contract for Subscription
      shall be twelve (12) months (&quot;Initial Term&quot;) and automatically renewed for
      subsequent periods of twelve (12) months (each a &quot;Renewal Term&quot;), unless either
      party notifies the other party no less than twenty-eight (28) calendar days prior to the end
      of the Initial Term or any Renewal Term that the Contract shall not renew. Ordinary
      termination rights are excluded during the Initial or Renewal Term.
      <br />
      <br />
      <b>B.5.2 Term and ordinary termination of Free Cloud Access</b>
      <br />
      <br />
      Unless otherwise specified, the Contract for Free Cloud Access shall be concluded for an
      unlimited period of time. Either party may terminate the Contract at any time.
      <br />
      <br />
      The continued use of Free Cloud Access by Customer shall be deemed as acceptance of the terms
      of this EULA (including without limitation to the Product Specification of Free Cloud Access)
      and continuation of the Contract thereunder. This shall also apply if Customer indicates
      disagreement with the EULA by any act or declaration but still uses the Free Cloud Access
      thereafter.
      <br />
      <br />
      <b>B.5.3 Extraordinary termination</b>
      <br />
      <br />
      LINK shall be entitled to extraordinarily terminate the Contract for breach in case of
      sections B.1.5 (Prohibited use), B.3.1 (Lawful use), B.3.2 (Export controls and economic
      sanctions), A.4 (Data protection), A.2.6 (Late payment). To the extent a DPA is required in
      accordance with section A.4, lack of such DPA or termination thereof shall also constitute
      cause for termination of the Contract. Either party&#39;s right to terminate for good cause
      remains unaffected.
      <br />
      <br />
      Notwithstanding the above, termination by the Customer based on non-performance of service in
      conformity with the Contract shall only be admissible if LINK had been given enough
      opportunities to eliminate the error/shortcoming and has failed to do so or the subsequent
      performance was unsuccessful.
      <br />
      <br />
      LINK shall use commercially reasonable efforts to eliminate Errors within a reasonable period
      of time following Customer&#39;s notification of such Errors, for which Customer shall provide
      comprehensive details of the circumstances relating to the Errors and supporting documentation
      (e.g., screenshots, protocol data) in its notification, as far as this is possible and can be
      reasonably expected. LINK may, at its sole option, eliminate Errors by delivering patches or
      updates, through Release Versions or otherwise. If the elimination of an Error is not
      available using financially reasonable efforts within a predictable time, LINK shall be
      entitled to provide temporary workarounds for such Error, provided that the functionalities
      and availability of the Services are not materially affected.
      <br />
      <br />
      <b>B.5.4 Termination notice</b>
      <br />
      <br />
      Termination notice, including the notification of non-renewal of Subscription pursuant to
      section B.5.1, must be made using the appropriate termination option in the LINK Cloud. LINK
      may also terminate a Contract by means of a respective notification within the Software.
      <br />
      <br />
      <b>B.5.5 Consequences of termination</b>
      <br />
      <br />
      Upon termination or expiration of the Contract:
      <br />
      <br />
      1. the granted License shall end forthwith, and Customer shall delete the Software from its
      computers and refrain from any further use of the Software; 2. Customer shall no longer have
      access to the data stored in the Software and the LINK account. Customer shall be solely
      responsible for exporting the data – as far as possible – prior to the termination or
      expiration of the term of the Contract by means of the Software functions and to store it for
      further use. LINK shall not be obligated to any further release of data. 3. LINK right to use
      non-personal or anonymous data in accordance with section A.5. shall survive the termination
      and remain unaffected. Customer&#39;s data that is processed for the purpose of performance of
      the Contract shall be deleted in compliance with applicable law, the Contract and the Data
      Processing Agreement, unless LINK is obligated by law to store it. If a deletion is only
      possible with unreasonable efforts (e.g., in backups), LINK shall be entitled to retain such
      data and restrict the further processing.
      <br />
      <br />
      <b>B.6. Apple Specific Terms</b>
      <br />
      <br />
      With respect to the use of the specific application of the Software for iOS operating system,
      available on the App Store (&quot;LINK iOS App&quot;), the following provisions shall apply:
      Apple Inc. (&quot;Apple&quot;) is not a party to any Contract concluded under this EULA and
      does not own and is not responsible for the LINK iOS App in any capacity or manner. Apple is
      not providing any warranty for the LINK iOS App except, if applicable, to refund the purchase
      price for it. Apple is not responsible for the Services, or any maintenance or support for the
      LINK iOS App, and will not be responsible for any other claims, losses, liabilities, damages,
      costs or expenses with respect to the LINK iOS App, including any third-party product
      liability claims, claims that the LINK iOS App fails to conform to any applicable legal or
      regulatory requirement, claims arising under consumer protection or similar legislation, and
      claims with respect to intellectual property infringement. Any inquiries or complaints
      relating to the use of the LINK iOS App, including those pertaining to intellectual property
      rights, must be directed to LINK in accordance with the notice provisions contained in this
      EULA. The license granted to Customer to use the LINK iOS App is a limited non-transferable
      license for the use on an Apple-branded product that runs Apple&#39;s iOS operating system and
      is owned or controlled by Customer, or as otherwise permitted by the Usage Rules set forth in
      Apple&#39;s Mobile App Store Terms of Service, except that the LINK iOS App may also be
      accessed and used by other accounts associated with Customer via Apple&#39;s Family Sharing or
      volume purchasing programs. In addition, Customer must comply with the terms of any
      third-party agreement applicable to Customer when using the LINK iOS App, such as Customer´s
      wireless data service agreement. Apple and Apple&#39;s subsidiaries are third-party
      beneficiaries of this EULA and, upon Customer´s acceptance of this EULA, will have the right
      (and will be deemed to have accepted the right) to enforce this EULA against Customer as a
      third-party beneficiary thereof; notwithstanding the foregoing, LINK&#39;s right to enter
      into, rescind or terminate any variation, waiver or settlement under this EULA is not subject
      to the consent of any third party.
      <br />
      <br />
      <b>C. Hardware Specific Terms</b>
      <br />
      <br />
      <b>
        The Hardware Specific Terms contain the terms and Conditions that additionally apply to your
        purchase and/or lease of physical goods, including LINKbridge, LINKMini or similar items
        (&quot;Hardware&quot;).
      </b>
      <br />
      <br />
      <b>C.1. Hardware Purchase</b>
      <br />
      <br />
      <b>C.1.1 Subject Matter</b>
      <br />
      <br />
      This Part will apply to sale of Hardware to and purchase by the Customer from LINK
      (&quot;Hardware Purchase&quot;).
      <br />
      <br />
      The subject matter of the Hardware Purchase shall be the supply of the Hardware as provided in
      the Contract and the transfer of ownership of the Hardware to and the payment of the purchase
      price agreed by the Customer.
      <br />
      <br />
      Any additional services related to the Hardware Purchase, including but not limited to the
      setup, installation, or trainings, shall not be provided under these Hardware Specific Terms.
      Such services may be agreed between the parties upon request but shall remain subject to a
      separate agreement and remuneration.
      <br />
      <br />
      <b>C.1.2 LINK Obligations</b>
      <br />
      <br />
      LINK shall grant to customer the ownership and possession of the Hardware pursuant to the
      conditions of these Hardware Purchase Terms.
      <br />
      <br />
      Unless otherwise agreed, the Hardware shall be delivered to the address provided in the
      Contract.
      <br />
      <br />
      Customer shall assume the risk of accidental destruction, loss or damage of the Hardware after
      LINK has handed over the Hardware to the shipping service provider (&quot;Passing of
      Risk&quot;).
      <br />
      <br />
      <b>C.1.3 Customer Obligations</b>
      <br />
      <br />
      Customer shall pay to LINK the purchase price and delivery fees as specified in the Contract.
      <br />
      <br />
      In deviation to section A.2.2 and unless otherwise agreed, the purchase price shall be due for
      payment upon Passing of Risk.
      <br />
      <br />
      Customer shall be responsible for the setup and configuration of the Hardware following the
      delivery thereof.
      <br />
      <br />
      <b>C.1.4 Reservation of Title</b>
      <br />
      <br />
      The Hardware shall remain the property of the LINK until the purchase price has been paid in
      full. This reservation of title shall be extended to other claims which LINK has against
      Customer resulting from the latter&#39;s business activities.
      <br />
      <br />
      Customer is not entitled to set off against claims of LINK, unless the counterclaims are
      undisputed or confirmed by final judicial award.
      <br />
      <br />
      <b>C.1.5 Hardware Requirements</b>
      <br />
      <br />
      The use of the Hardware by the Customer will be subject to specifications as provided by LINK.
      <br />
      <br />
      <b>C.1.6 Limitation of Warranty / Warranties</b>
      <br />
      <br />
      LINK warrants that the Hardware is free of any material defects and defects of title upon the
      Passing of Risk.
      <br />
      <br />
      Customer shall examine the Hardware immediately after receipt, insofar as this is feasible in
      the ordinary course of business, and notify LINK of any found defects without delay, failing
      which, the Hardware shall be deemed to have been accepted as in compliance with Contract,
      unless the defect was hidden and not recognizable during the ordinary examination.
      <br />
      <br />
      In case of material defects of the Hardware or defects in title thereof and at LINK&#39;s
      discretion, LINK shall either fix the defect or make available to Customer replacement
      Hardware which is free from defect.
      <br />
      <br />
      LINK reserves the right to assign to the Customer any claims arising from LINK liability for
      material defects and defects of title against the manufacturer, the reseller or other third
      parties.
      <br />
      <br />
      Any claims for damages based on breach of warranty or defective performance shall be subject
      to limitation of liability stipulated in the Jurisdiction Specific Terms.
      <br />
      <br />
      <b>C.2. Hardware Lease</b>
      <br />
      <br />
      <b>C.2.1 Subject Matter</b>
      <br />
      <br />
      This Part, the hardware lease terms, will apply to the non-permanent transfer for use of
      Hardware during the duration of the Contract (&quot;Leased Hardware&quot;) to the Customer by
      LINK (&quot;Hardware Lease&quot;).
      <br />
      <br />
      The subject matter of the Hardware Lease shall be the supply of the Hardware as provided in
      the applicable Contract and the non-permanent transfer of usage rights and possession of the
      Hardware and the payment of the agreed fee.
      <br />
      <br />
      Unless otherwise specified in this section, the Hardware Specific Terms shall apply to the
      Hardware Lease mutatis mutandis.
      <br />
      <br />
      <b>C.2.2 LINK Obligations</b>
      <br />
      <br />
      LINK shall grant to Customer the usage rights to and possession of the Hardware for the term
      of the Hardware Lease.
      <br />
      <br />
      <b>C.2.3 Customer Obligations</b>
      <br />
      <br />
      Upon delivery thereof, Customer shall ensure the proper functioning of the Leased Hardware
      before commencing use. During the duration of the Hardware Lease, Customer shall treat the
      Leased Hardware with due care as per the manufacturer&#39;s manual and recommendations.
      <br />
      <br />
      Customer shall bear all operating costs incurred when using the Leased Hardware, including all
      cleaning and running costs.
      <br />
      <br />
      All maintenance and repair costs as well as any necessary repairs of the equipment, components
      and accessories of the Leased Hardware which are based on the use by the Customer shall be
      carried out by the Customer at Customer&#39;s own expense.
      <br />
      <br />
      Customer shall notify LINK immediately of any damage to the Leased Hardware, providing where
      available, comprehensive information on the cause of and the person responsible for the
      damage. In case of damages to the Leased Hardware, which do not regularly occur solely as a
      result of normal contractual wear and tear, Customer shall bear the burden of proof that the
      deterioration of the Leased Object was not caused by him and was not his fault.
      <br />
      <br />
      Customer shall require the prior written consent by LINK in case of Customer wishing for third
      parties to use the Leased Hardware, including but not limited to subleasing or other complete
      or partial transfer of possession of the Leased Hardware (together the &quot;Transfer of
      Use&quot;). In the event of any Transfer of Use to third parties, Customer shall be liable for
      all damage caused by the third party to whom the use of the Leased Hardware was transferred.
      <br />
      <br />
      <b>C.2.4 Term and termination</b>
      <br />
      <br />
      Unless otherwise specified in the Contract, the initial term of the Hardware Lease shall be
      twelve (12) months (&quot;Initial Term&quot;) and be automatically renewed for subsequent
      periods of twelve (12) months (each a &quot;Renewal Term&quot;), unless either party notifies
      the other party no less than twenty-eight (28) days prior to the end of the Initial Term or
      any Renewal Term that the Contract shall not renew.
      <br />
      <br />
      A termination by Customer based on non-performance of service in conformity with the Contract
      shall only be admissible if LINK had been given enough opportunities to eliminate the defects
      and has failed to do so or the subsequent performance was unsuccessful.
      <br />
      <br />
      Unless otherwise specified in the Contract, the initial term of the Hardware Lease shall be
      twelve (12) months (&quot;Initial Term&quot;) and be automatically renewed for subsequent
      periods of twelve (12) months (each a &quot;Renewal Term&quot;), unless either party notifies
      the other party no less than twenty-eight (28) days prior to the end of the Initial Term or
      any Renewal Term that the Contract shall not renew.
      <br />
      <br />
      <b>C.2.5 Consequence of termination</b>
      <br />
      <br />
      Customer shall at his own costs return the Leased Hardware at the end of the Hardware Lease
      including all accessories, manuals or documents. Should any modifications have been made to
      the Leased Hardware during the time of the Hardware Lease, Customer shall return the rented
      item to its original condition upon return.
      <br />
      <br />
      <b>D. Product Specific Terms</b>
      <br />
      <br />
      <b>
        The Product Specific Terms include terms and conditions that additionally apply to your use
        of a specific type of LINK product (each, a &quot;Product&quot;), if and to the extent the
        terms and conditions for a specific LINK Product are provided herein. The Product Specific
        Terms include a description of features, limits of use and system requirements.{' '}
      </b>
      <br />
      <br />
      Where applicable, and for informational purposes only, the Product Specific Terms also link to
      the corresponding:
      <br />
      <br />
      - Data Processing Agreement (DPA): This explains and regulates how LINK processes data on your
      behalf; and - Product Privacy Policy: This policy describes how LINK, in its function as a
      data controller, collects, uses, stores and processes your Personal Data in connection with
      your use of the service. It also describes your rights of information, i.e., of access to and
      correction of your personal data.
      <br />
      <br />
      <b>D.1. Product Specific Terms – LINKbridge</b>
      <br />
      <br />
      <b>
        These Product Specific Terms apply to LINKbridge, the comprehensive hardware solution for
        remote access, remote control and remote support of your vessel.
      </b>
      <br />
      <br />
      <b>D.1.1 Product Description</b>
      <br />
      <br />
      See LINKbridge Brochure
      <br />
      <br />
      <b>D.1.2 System Requirements</b>
      <br />
      <br />
      LINKbridge is a self-contained system. All the hardware needed to run the system is contained
      inside the product. Externally, LINK and Iridium approved antennas should be used. All power
      connections to the LINKbridge should be fitted with a 3A fuse with cabling appropriately sized
      for the application.
      <br />
      <br />
      <b>D.1.3 Lifecycle Policy</b>
      <br />
      <br />
      LINK endeavours to support LINKbridge for as long as LINK – at its sole discretion –
      determines this to be feasible.
      <br />
      <br />
      <b>D.1.4 Link to applicable DPA and PP</b>
      <br />
      <br />
      LINKbridge communicates to the LINK Cloud. Please refer to our data processing agreements and
      privacy policies. Customer acknowledges that data is transmitted and stored in accordance with
      these, and that raw data may be visible to LINK employees. LINK takes all reasonable measures
      to ensure data security, whilst still enabling the functionality intended for the product.
      <br />
      <br />
      <b>D.2. Product Specific Terms – Remote Management</b>
      <br />
      <br />
      <b>The following terms will apply to Customer&#39;s use of the LINKCloud product.</b>
      <br />
      <br />
      <b>D.2.1 Additional Use Conditions</b>
      <br />
      <br />
      The LINKCloud is able to function without additional hardware such as the LINKbridge, through
      Free Access. This is, however, only intended to be a gateway to enable the integration of a
      LINKbridge (or equivalent) to enable live vessel data reporting through the purchase of a
      subscription. The LINKCloud provides remote access and control to a vessel via a LINKbridge.
      Customer acknowledges that data is transmitted in this process which may be visible to LINK
      employees. LINK takes all reasonable measures to ensure data security, whilst still enabling
      the functionality intended for the product.
      <br />
      <br />
      <b>D.2.2 Customer Content</b>
      <br />
      <br />
      Depending on the subscription acquired by Customer, LINK may make available to Customer
      functions for uploading, storing or integrating content by Customer including but not limited
      to texts, graphics, audio or video files or other digital data and content (&quot;Customer
      Content&quot;). Other than as indicated herein, Customer shall retain all rights to and be
      wholly responsible for the Customer Content.
      <br />
      <br />
      Customer ensures that it holds all necessary rights to the Customer Content (e.g., rights to
      images, trademarks, copyrights, etc.) for processing within the scope of the Contract and
      subsequently assumes the sole liability and defense against all claims of third parties,
      asserting claims against LINK due to the alleged violation of trademark rights in connection
      with Customer Content.
      <br />
      <br />
      Customer shall ensure that the Customer Content does not contain Prohibited Customer Content.
      &quot;Prohibited Customer Content&quot; is defined as content that (i) infringes the rights of
      third parties or violates applicable law; (ii) is illegal, racist or pornographic in nature,
      glorifies or incites violence, promotes terrorist organizations, incites criminal activity or
      contains defamatory statements; or (iii) contains or distributes software viruses or other
      malicious software or harmful files such as Trojan horses, worms or spyware. LINK is not
      obliged to check whether the Customer Content contains prohibited Customer Content. However,
      LINK reserves the right to refuse or suspend the provision of the Service in whole or in part
      if and to the extent that prohibited Customer Content is uploaded using a user&#39;s access
      data associated with the Customer&#39;s license or account.
      <br />
      <br />
      Unless the parties expressly agree in writing to the contrary, if the Customer is a Covered
      Entity, Business Associate, or Representative of a Covered Entity or Business Associate
      (pursuant to the definition of these terms in 45 C.F.R § 160.103), the Customer agrees not to
      use any component, function, or other facility in order to create, receive, manage, or
      transmit any &quot;protected health information&quot; of any kind (pursuant to the definition
      of this term in 45 C.F.R § 160.103) that would result in LINK being considered a Business
      Associate or a Representative of a Business Associate.
      <br />
      <br />
      <b>D.2.3 Product Description</b>
      <br />
      <br />
      See LINK Brochure
      <br />
      <br />
      <b>D.2.4 System Requirements</b>
      <br />
      <br />
      LINKCloud runs on a web browser. For security reasons we recommend that Customer uses a
      current and up to date operating system and web browser.
      <br />
      <br />
      <b>D.2.5 Lifecycle Policy</b>
      <br />
      <br />
      LINKCloud is continually evolving software that is constantly updated for all uses. LINK
      reserves the right to alter this arrangement in the future.
      <br />
      <br />
      <b>D.2.6 Link to applicable DPA and PP</b>
      <br />
      <br />
      LINKCloud communicates to the LINKbridge. Please refer to our data processing agreements and
      privacy policies. Customer acknowledges that data is transmitted and stored in accordance with
      these, and that raw data may be visible to LINK employees. LINK takes all reasonable measures
      to ensure data security, whilst still enabling the functionality intended for the product.
      <br />
      <br />
      <b>D.3. Product Specific Terms – LINK Smart Phone Apps</b>
      <br />
      <br />
      <b>
        The following terms will apply to Customer&#39;s use of the LINK&#39;s Smart Phone Apps.
      </b>
      <br />
      <br />
      <b>D.3.1 Product Description</b>
      <br />
      <br />
      LINK Smart Phone Apps provide functionality akin to that provided by LINKCloud but via an
      experience optimised for mobile
      <br />
      <br />
      <b>D.3.2 System Requirements</b>
      <br />
      <br />
      Please consult the systems requirements listed at the time in the IOS and Android App stores.
      <br />
      <br />
      <b>D.3.3 Lifecycle Policy</b>
      <br />
      <br />
      LINK Smart Phone Apps are continually updated on a regular basis
      <br />
      <br />
      <b>D.3.4 Link to applicable DPA and PP</b>
      <br />
      <br />
      LINK Smart Phone App communicates to the LINKbridge and LINKCloud. Please refer to our data
      processing agreements and privacy policies. Customer acknowledges that data is transmitted and
      stored in accordance with these, and that raw data may be visible to LINK employees. LINK
      takes all reasonable measures to ensure data security, whilst still enabling the functionality
      intended for the product.
      <br />
      <br />
      <b>E. Jurisdiction Specific Terms</b>
      <br />
      <br />
      <b>E.1. Jurisdiction Specific Terms</b>
      <br />
      <br />
      LINK&#39;s liability is governed exclusively by the law of England and Wales.
      <br />
      <br />
      <b>E.1.1 Limitation of Liability in case of Subscription</b>
      <br />
      <br />
      <b>E.1.1.1 Exclusion in certain cases</b>
      <br />
      <br />
      LINK shall be liable for damages in accordance with the statutory provisions, if and to the
      extent such damages
      <br />
      <br />
      (i) have been caused by LINK intentionally or grossly negligently, or
      <br />
      <br />
      (ii) have been caused by LINK by slight negligence and are due to breaches of cardinal
      contractual obligations, the fulfilment of which is essential for the proper performance of
      the Contract and the compliance with which can be typically expected in the case of a contract
      of this type (&quot;Material Obligations&quot;).
      <br />
      <br />
      Any further liability of LINK shall be excluded irrespective of the legal basis, unless LINK
      is mandatorily liable in accordance with applicable law, in particular due to injury to life,
      body or health of an individual, due to the provision of an express warranty, fraudulent
      concealment of a defect or due to the provisions of the specific UK statute.
      <br />
      <br />
      <b>E.1.1.2 Limited Liability for Foreseeable Damages</b>
      <br />
      <br />
      In the case of slightly negligent breach of Material Obligations, LINK shall only be liable
      for damages foreseeable when the Contract was concluded and typical for the type of contract.
      <br />
      <br />
      <b>E.1.1.3 Maximum Liability Amount</b>
      <br />
      <br />
      Notwithstanding the provision in section E.1.1.2, in the event of a slightly negligent breach
      of a Material Obligation, the maximum amount of LINK&#39;s liability for all damaging events
      occurring in the same year of Subscription (i.e. in an Initial or Renewal Term) is capped by
      the higher of 100% of the fees paid by Customer in the year of the damaging event or GBP1,500,
      – (One thousand five hundred pound). If the maximum liability amount is not reached in one
      year, this shall not increase the maximum liability amount in the following Renewal Term.
      <br />
      <br />
      <b>E.1.1.4 Exclusion of Strict Liability</b>
      <br />
      <br />
      Strict liability (liability without fault) for defects which already existed when the Contract
      was concluded shall be excluded.
      <br />
      <br />
      <b>E.1.1.5 Limited liability in case of Free License</b>
      <br />
      <br />
      Customer acknowledges that LINK shall be excluded from all forms liability for damages caused
      by the use of Free Cloud Access.
      <br />
      <br />
      <b>E.1.1.6 Employees and agents of LINK</b>
      <br />
      <br />
      The limitations of liability pursuant to section E.1.1 shall also apply to claims against
      employees or agents of LINK.
      <br />
      <br />
      <b>E1.1.7 Equitable Relief</b>
      <br />
      <br />
      Customer recognizes and agrees that there may be no adequate remedy at law for a breach of
      this Contract, especially with respect to Prohibited Use and that such breach would
      irreparably harm LINK for which monetary damages would not be an adequate remedy and that LINK
      is entitled, in addition to its other rights and remedies, to seek equitable relief.&quot;
      <br />
      <br />
      <b>E.1.2 Applicable Law and jurisdiction</b>
      <br />
      <br />
      The Contract and any disputes in connection with it shall be exclusively governed by the law
      of the England and Wales.
      <br />
      <br />
      The exclusive place of jurisdiction shall be Newcastle Upon Tyne, United Kingdom. LINK shall
      still be entitled to file a suit at the Customer&#39;s domicile.
      <br />
      <br />
      <b>E.1.3 Disclaimer</b>
      <br />
      <br />
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE WARRANTIES SET FORTH IN THE CONTRACT
      ARE CUSTOMER&#39;S EXCLUSIVE WARRANTIES AND ARE IN LIEU OF ALL OTHER WARRANTIES, WHETHER
      EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
      SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT OF INTELLECTUAL
      PROPERTY RIGHTS. LINK MAKES NO WARRANTIES OR REPRESENTATIONS THAT THE SOFTWARE OR ANY SERVICES
      WILL MEET CUSTOMER&#39;S REQUIREMENTS OR THAT OPERATION OR USE OF THE SOFTWARE OR SERVICES
      WILL BE UNINTERRUPTED OR ERROR-FREE. CUSTOMER MAY HAVE OTHER WARRANTY RIGHTS, WHICH MAY VARY
      FROM STATE TO STATE AND COUNTRY TO COUNTRY.
      <br />
      <br />
    </div>
  );
};

export default EULA;
