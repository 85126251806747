// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './CardTabs.module.scss';

export interface Tab {
  display: string;
  code: string;
}

type Props = {
  availableTabs: Tab[];
  currentTab: string;
  path: string;
};

function CardTabs(props: Props): ReactElement {
  const { availableTabs, currentTab, path } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.tabButtonsWrapper}>
      {availableTabs.map((tab, idx) => {
        return (
          <button
            key={idx}
            onClick={() => navigate(path + tab.code.toLowerCase())}
            className={classNames([
              styles.tabButton,
              currentTab.toUpperCase() === tab.code && styles.tabButtonActive,
            ])}>
            {tab.display}
          </button>
        );
      })}
    </div>
  );
}

export default CardTabs;
